@import "boostrap-custom";
@import "bootstrap";

@import "ember-basic-dropdown";
@import "ember-power-calendar";
@import "light-table";

@import "datepicker";
@import "ember-power-datepicker";
/* Change cursor for rows of light-table */

.demo-datepicker-medium {
  @include ember-power-calendar($cell-size: 50px);
}

.lt-row {
  cursor: pointer;
}

tr.completed {
  background: transparentize(green, 0.9);
}

h2 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 20px;
  border-bottom: 1px solid transparentize(black, 0.5);
  padding-bottom: 5px;
}

/* Make a.active links styles work the same as li.active */
.navbar-default .navbar-nav {
  li {
    a.active {
      &, &:hover {
        color: white;
        background-color: #235fb3;
      }
    }
  }
}

.form-text {
  padding-top: $padding-base-vertical+1px;
  border-color: $input-bg!important;
  @include box-shadow(inset 0 1px 1px $input-bg);
}

.glyphicon.spinning {
  animation: spin 1s infinite linear;
  -webkit-animation: spin2 1s infinite linear;
}

@keyframes spin {
  from { transform: scale(1) rotate(0deg);}
  to { transform: scale(1) rotate(360deg);}
}

@-webkit-keyframes spin2 {
  from { -webkit-transform: rotate(0deg);}
  to { -webkit-transform: rotate(360deg);}
}

.dropdown-menu {
  position: relative;
  top: auto;
  left: auto;
  display: block;
  padding: 5px;
}

.task-options {
  padding: 5px;
}

.task-options-dropdown {
  input[type=checkbox] {
    transform: scale(1.4);
    margin: 10px;
  }

  input {
    margin-bottom: 5px;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
}

.dropdown > a, .dropdown > button {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  background-color: white;
  border: 1px solid #ccc;
  padding: 6px 20px 6px 10px;
  border-radius: 4px;
  display: inline-block;
  color: black;
  text-decoration: none;
}

.dropdown > a:before, .dropdown > button:before {
  position: absolute;
  right: 7px;
  top: 14px;
  content: ' ';
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
}

.dropdown input[type=checkbox] {
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  margin: 0px;
  opacity: 0;
}

.dropdown input[type=checkbox]:checked {
  position: fixed;
  z-index:+0;
  top: 0px; left: 0px; 
  right: 0px; bottom: 0px;
}

.dropdown ul {
  position: absolute;
  top: 30px;
  border: 1px solid #ccc;
  border-radius: 3px;
  left: 0px;
  list-style: none;
  padding: 4px 0px;
  display: none;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0,0,0,.175);
}

.dropdown input[type=checkbox]:checked + ul {
  display: block;
}

.dropdown ul li {
  display: block;
  padding: 2px 5px;
  white-space: nowrap;
  min-width: 100px;
}

.dropdown ul li a {
  text-decoration: none;
  display: block;
  color: black
}

.dropdown .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
  font-size: 1px;
  padding: 0;
}


@import "ember-bootstrap/bootstrap";

@keyframes drop-fade-below {
  0%   {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes drop-fade-above {
  0%   {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.slide-fade, %slide-fade {
  will-change: transform, opacity;
  &.ember-basic-dropdown-content--below.ember-basic-dropdown--transitioning-in {
    animation: drop-fade-below .15s;
  }
  &.ember-basic-dropdown-content--below.ember-basic-dropdown--transitioning-out {
    animation: drop-fade-below .15s reverse;
  }
  &.ember-basic-dropdown-content--above.ember-basic-dropdown--transitioning-in {
    animation: drop-fade-above .15s;
  }
  &.ember-basic-dropdown-content--above.ember-basic-dropdown--transitioning-out {
    animation: drop-fade-above .15s reverse;
  }
}

//
//
// Bootstrap
//
//

.dropdown-menu.ember-basic-dropdown-content {
  display: block;
}
.ember-basic-dropdown-content.bootstrap-datepicker-theme {
  margin-top: 5px;
	background: #ffffff;
	border: 1px solid #cccccc;
  &:after, &:before {
    bottom: 100%;
    left: 15px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 5px;
    margin-left: -5px;
  }
  &:before {
    border-color: rgba(204, 204, 204, 0);
    border-bottom-color: #cccccc;
    border-width: 6px;
    margin-left: -6px;
  }
}


//
//
// MATERIAL
//
//
.md-datepicker-input-container {
  position: relative;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: inline-block;
  width: auto;
  border-bottom-color: rgba(0,0,0,0.12);
}

.md-datepicker-input {
  font-size: 14px;
  box-sizing: border-box;
  border: none;
  box-shadow: none;
  outline: none;
  background: transparent;
  min-width: 120px;
  max-width: 328px;
  padding: 0 0 5px;
  color: rgba(0,0,0,0.87);
}

.md-datepicker-triangle-button.md-button.md-icon-button {
  height: 36px;
  width: 36px;
  position: absolute;
  padding: 8px;
}
.md-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  min-height: 36px;
  min-width: 88px;
  line-height: 36px;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  border-radius: 3px;
  box-sizing: border-box;
  user-select: none;
  border: 0;
  padding: 0 6px;
  margin: 6px 8px;
  background: transparent;
  color: currentColor;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  overflow: hidden;
  transition: box-shadow .4s cubic-bezier(.25,.8,.25,1),background-color .4s cubic-bezier(.25,.8,.25,1);
}

.md-button, .md-button:focus {
  outline: none;
}

.md-datepicker-triangle-button {
  position: absolute;
  right: 0;
  top: 5px;
  transform: translateY(-25%) translateX(45%);
}

.md-button.md-icon-button {
  margin: 0 6px;
  height: 40px;
  min-width: 0;
  line-height: 24px;
  padding: 8px;
  width: 40px;
  border-radius: 50%;
}
.md-datepicker-triangle-button .md-datepicker-expand-triangle {
  border-top-color: rgba(0,0,0,0.38);
}
.md-datepicker-expand-triangle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
}
.ember-basic-dropdown-content.material-datepicker-theme {
  @extend %slide-fade;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
  @include ember-power-calendar($cell-size: 40px, $row-padding-left: 15px, $row-padding-right: 15px);
  .ember-power-calendar-day {
    background-color: #fff;
    color: rgba(0,0,0,0.87);
    border-radius: 50%;
    box-shadow: none;
    &:hover {
      background-color: #e0e0e0;
    }
  }
  .ember-power-calendar-weekdays {
    background-color: #e0e0e0;
  }
  .day-number {
    border-radius: 50%;
    &:hover {
      background-color: #e0e0e0;
    }
  }
  .ember-power-calendar-day--selected, .ember-power-calendar-day--selected:hover {
    background: rgb(16,108,200);
    color: rgba(255,255,255,0.87);
    font-weight: normal;
  }
  .ember-power-calendar-nav-title {
    line-height: 3;
  }
  .ember-power-calendar-nav-control {
    color: rgba(0,0,0,0.54);
    font-family: arial;
    font-size: 200%;
  }
}